<template>
  <div class="mt-4">
    <button @click="$router.go(-1)" class="btn btn-sm btn-default">Back</button>
    <div class="card mt-2 shadow p-4">
      <div class="row">
        <div class="col-md-7">
          <text-input
            :value="post_data.name || ''"
            :errors="errors.name"
            label="Name"
            placeholder="Enter the name of the industry"
            @type="(v) => (post_data.name = v)"
          />
        </div>
        <div class="col-md-7 mb-3">
          <label class="form-control-label">Description:</label>
          <rich-text
            id="productdesc"
            @change="(v) => (post_data.description = v)"
            
          ></rich-text>
        </div>
        <div class="col-md-6" v-if="false">
          <label class="form-control-label">Picture:</label>
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFileLang1"
              lang="en"
              v-show="false"
              @change="process_file"
            />
            <label class="custom-file-label p-3" for="customFileLang1">
              <span v-if="Image.url" class="avatar h-75">
                <img alt="Image placeholder" :src="Image.url" />
              </span>
              <span class="ml-3 mt--3">{{ Image.name }}</span>
            </label>
          </div>
        </div>
        <button @click="submit" class="btn btn-block col-md-7 mt-3 btn-default">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../components/TextInput";
import RichTextEditor from "../../components/RichTextEditor";
import { alert } from "../../utils/alertUtils";
import axios from "axios";
export default {
  name: "New Industry",
  components: {
    "text-input": TextInput,
    "rich-text": RichTextEditor,
  },
  data() {
    return {
      errors: {},
      post_data: {},
      Image: {},
    };
  },
  methods: {
    process_file: function (e) {
      this.post_data.picture = e.target.files[0];
      this.Image.url = URL.createObjectURL(e.target.files[0]);
      this.Image.name = e.target.files[0].name;
    },
    submit: function () {
      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}/api/industries`,
          this.post_data,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          alert.success_center("Industry created successfully!");
          this.post_data = {};
          this.$router.push({ name: "Industries" });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.errors = err.response.data;
            alert.error(
              "An Error occured, kindly check the for for specific errors"
            );
          } else {
            alert.error("An Error occured, Please try again!");
          }
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>